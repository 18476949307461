import React, { useEffect } from 'react';
import {
    Route,
    Link, // Used to pass state to Link component
    useHistory, // Tra le sue funzionalità esiste la possibilità di fare redirect (ad esempio come viene fatto per un corso appena aggiunto)
    Redirect,
} from "react-router-dom";
//import Swal from 'sweetalert2';

// Local imports
import { generateThreadID } from '../utils/utility_functions';
import { firebase_config } from '../config/firebase_config';
// Type imports
import type { CourseType, LessonType } from '../types/types';
import type { AppStateType } from '../App';

// Local components
import { Lesson } from './Lesson';
import { Course } from './Course';
import { Courses, isTeacher , isTeacherActingAsTeacher, teacherToggleRole} from './Courses';

import home_accademia from '../static/images/home_accademia.jpg';

// Firestore imports
import { initializeApp } from "firebase/app";
import { /* collection, */ doc, setDoc, /* getDocs, getDoc, */ getFirestore } from "firebase/firestore";
import { getAuth, signOut } from "firebase/auth";

const app = initializeApp(firebase_config);
const auth = getAuth();
export const db = getFirestore(app);

function Academy({ state, stateSubstitute, stateAdd, stateDelete }: {
    state: AppStateType;
    stateSubstitute: (path: string, value: any) => void;
    stateAdd: (path: string, value: any) => void;
    stateDelete: (path: string) => void;
}) {
    const { lessons, courses, new_course_added, new_lesson_added, lesson_updated, curriculum, curriculum_to_save_to_db } = state;
    // @ts-ignore
    async function updateCurriculum() { // WTF
        const curriculum_reference = doc(db, 'curricula', curriculum.id);
        await setDoc(curriculum_reference, curriculum);
    }
    useEffect(() => { //WTF
        if (curriculum_to_save_to_db) {
            updateCurriculum().then(() => stateSubstitute('curriculum_to_save_to_db', false));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [curriculum_to_save_to_db]);
    const history = useHistory();
    if (!state.is_logged_in) return <Redirect to={{
        pathname: '/login',
        // search: 'token',
    }} />;
    if (!lessons.length || !courses.length) return <div>Loading...</div>;
    let course_id: string = null;
    let lesson_id: string = null;
    let current_course: CourseType = null;
    let current_lesson: LessonType = null;
    const pathname_includes_course = history.location.pathname.includes('/course/');
    if (pathname_includes_course) {
        course_id = history.location.pathname.split('/course/')?.[1];
    }
    const pathname_includes_lesson = history.location.pathname.includes('/lesson/');
    if (pathname_includes_lesson) {
        lesson_id = history.location.pathname.split('/lesson/')[1];
        current_lesson = lessons.find(l => l.id === lesson_id);
        //@ts-ignore
        course_id = courses.find(c => c.id === history.location?.state?.course_id);
        if (!course_id) {
            current_course = courses.find(c => c.lessons.some(l => l.id === lesson_id));
            course_id = current_course.id;
        }
    }
    return <div>
        <div className='p-4 bg-white space-y-4 flex justify-between shadow'>
            <div className='flex flex-col space-y-2'>
                <div className='flex text-base lg:text-2xl space-x-2'>
                    <Link className='no-underline hover:underline text-gray-900 hover:bg-gray-200 active:bg-gray-300' to="/courses">{/*T_R*/'Corsi'}</Link>
                    {!history.location.pathname.includes('/course/') ? null :
                        <>
                            <span className='ml-1 mr-1'>
                                {'>'}
                            </span>
                            <Link
                                className='no-underline hover:underline text-gray-900 hover:bg-gray-200 active:bg-gray-300'
                                to={history.location.pathname}
                            >{courses.find(c => c.id === course_id)?.title}</Link>
                        </>
                    }
                    {!history.location.pathname.includes('/lesson/') ? null :
                        <>
                            <span className='ml-1 mr-1'>
                                {'>'}
                            </span>
                            <Link
                                className='no-underline hover:underline text-gray-900 hover:bg-gray-200 active:bg-gray-300'
                                to={{
                                    //@ts-ignore
                                    pathname: `/course/${history.location.state?.course_id || course_id}`,
                                    state: history.location.state
                                    //@ts-ignore
                                }}>{courses.find(c => c.id === (history.location.state?.course_id || course_id))?.title}</Link>

                            <span className='ml-1 mr-1'>
                                {'>'}
                            </span>
                            <Link
                                className='no-underline hover:underline text-gray-900 hover:bg-gray-200 active:bg-gray-300'
                                to={{
                                    pathname: history.location.pathname,
                                    state: history.location.state
                                }}>{current_lesson?.title}</Link>
                        </>}
                </div>
                {!history.location.pathname.includes('/course/') ? null :
                    <Link to="/courses"><button className='bg-transparent p-2 border rounded-md cursor-pointer'>
                        <svg className='ciao-icon-svg' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" /></svg>
                        {/*T_R*/'Torna ai corsi'}
                    </button></Link>}
                {!history.location.pathname.includes('lesson') ? null :
                    <Link to={{
                        //@ts-ignore
                        pathname: `/course/${history.location.state?.course_id}`,
                        state: history.location.state
                    }}>
                        <button className='bg-transparent p-2 border rounded-md cursor-pointer'>
                            <svg className='ciao-icon-svg' xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.41 7.41L14 6l-6 6 6 6 1.41-1.41L10.83 12l4.58-4.59z" /></svg>
                            {/*T_R*/'Torna alle lezioni'}
                        </button>
                    </Link>}
            </div>
            <div className='m-0 flex space-x-4' style={{ maxHeight: '2.5rem' }}>
                {!auth ? null :
                <>
                    {state.curriculum?.hic_user?.username ?
                        <h2 className={'bnb--color-accent'}>{/*T_R*/`Bentornato!: ${state.curriculum?.hic_user?.username ?? ''}`}</h2>
                    : null} 
                    {isTeacher(state) ? <>
                        <h2 className={'bnb--color-accent'}><b>{/*TR*/`Insegnante: ${isTeacherActingAsTeacher(state) ? /*TR*/`modalita' progettazione` : /*TR*/'simulazione studente'}`}</b></h2>
                        <button
                            className='bg-gray-100 cursor-pointer text-gray-900 hover:bg-gray-200 rounded'
                            onClick={() =>  teacherToggleRole({state, stateSubstitute})}
                        >{/*T_R*/'Cambia ruolo'}</button>
                    </> : null}
                   
                    <button
                        className='bg-gray-100 cursor-pointer text-gray-900 hover:bg-gray-200 rounded'
                        onClick={() => {
                        signOut(auth).then(() => {
                            stateSubstitute('is_logged_in', false);
                        });
                    }}>{/*T_R*/'Logout'}</button>
                </>
                }
                {/* <button
                    className='bg-transparent p-2 border rounded-md cursor-pointer ml-4'
                    onClick={async () => {
                        if (new_course_added || new_lesson_added || lesson_updated || curriculum_to_save_to_db) {
                            const confirm_result = await Swal.fire({
                                icon: 'question',
                                title: 'Sei sicuro di voler uscire?',
                                text: 'Hai ancora delle modifiche non salvate, che andrebbero perse',
                                confirmButtonText: 'Lo so, esci comunque',
                                cancelButtonText: 'Rimani su questa pagina',
                                showCancelButton: true,
                            });
                            if (!confirm_result.value) return;
                        }
                        window.location.assign('https://www.ciaobnb.com');
                    }}
                >
                    <svg className='ciao-icon-svg' xmlns="http://www.w3.org/2000/svg" enableBackground="new 0 0 24 24" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><g><path d="M0,0h24v24H0V0z" fill="none" /></g><g><path d="M17,8l-1.41,1.41L17.17,11H9v2h8.17l-1.58,1.58L17,16l4-4L17,8z M5,5h7V3H5C3.9,3,3,3.9,3,5v14c0,1.1,0.9,2,2,2h7v-2H5V5z" /></g></svg>
                    {'Torna a Hotel In Cloud'}
                </button> */}
            </div>
        </div>
        {history.location.pathname.includes('/courses') && <div className='p-4 lg:ciao-w-1_3'>
            <img className='w-full' alt='hero' src={home_accademia} />
        </div>}
        <div className='bg-gray-100 p-4'>
            {!isTeacherActingAsTeacher(state)/*curriculum?.hic_user?.academy_roles?.includes('student')*/ && curriculum === null ? null : <>
                <Route path={'/courses'}>
                    <div className='lg:ciao-w-1_3 space-y-4'>
                        <h2>{/*T_R*/'I tuoi corsi'}</h2>
                        <Courses
                            state={state}
                            stateDelete={stateDelete}
                        />
                        {isTeacherActingAsTeacher(state) ? 
                            <button
                                className='inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer'
                                onClick={async () => {
                                    let new_course_id = generateThreadID(5);
                                    stateSubstitute('new_course_added', new_course_id);
                                    stateAdd('courses', {
                                        title: '',
                                        subtitle: '',
                                        id: new_course_id,
                                        lessons: [],
                                    });
                                    history.push(`/course/${new_course_id}`);
                                }}>{/*T_R*/'Aggiungi un nuovo corso'}</button>
                        : null}
                    </div>

                </Route>
                {courses.map((course: CourseType, course_index: number) => {
                    return <Route exact key={`/course/${course.id}`} path={`/course/${course.id}`}>
                        <Course
                            key={`course-${course_index}`}
                            course_index={course_index}
                            state={state}
                            course={course}
                            stateAdd={stateAdd}
                            stateDelete={stateDelete}
                            stateSubstitute={stateSubstitute} />
                    </Route>
                })}
                {lessons.map((lesson: LessonType, lesson_index: number) => {
                    return <Route exact key={`/lesson/${lesson.id}`} path={`/lesson/${lesson.id}`}>
                        <Lesson
                            key={`lesson-${lesson.id}`}
                            lesson={lesson}
                            lesson_index={lesson_index}
                            stateSubstitute={stateSubstitute}
                            stateAdd={stateAdd}
                            stateDelete={stateDelete}
                            state={state}
                        />
                    </Route>
                })}
            </>
            }
        </div>
        <Route exact path='/'>
            <Redirect to='/courses' />
        </Route>
    </div>
}

export { Academy };