import React, { useEffect, useReducer } from 'react';
import { Academy } from './components/Academy';

import { reducerGeneric, stateChangeGenericDispatch } from './utils/reducer';
import type { CourseType, CurriculumType, LessonType } from './types/types';
import './App.css';
import {
    BrowserRouter as Router,
    Switch,
    Route,
    // Link,
    Redirect,
} from "react-router-dom";

import { decode as jwtDecode } from 'jsonwebtoken';

// Firestore imports
import { initializeApp } from "firebase/app";
import {
    collection,
    doc,
    getDocs,
    // getDoc,
    setDoc,
    getFirestore,
    query,
    where,
    updateDoc
} from "firebase/firestore";
import { getAuth, signInWithCustomToken, onAuthStateChanged, signOut } from "firebase/auth";
// import { getAnalytics } from "firebase/analytics";

import { firebase_config } from './config/firebase_config';
import { local_config } from './config/local_config';
import { generateThreadID } from './utils/utility_functions';
const app = initializeApp(firebase_config);
export const db = getFirestore(app);

const courses_collection = collection(db, 'courses');
const lessons_collection = collection(db, 'lessons');
const curricula_collection = collection(db, 'curricula');
const auth = getAuth();

// TODO Una volta fatto il login verranno recuperate le lezioni, i corsi e il curriculum dell'utente (se questo è "student")
export type AppStateType = {
    username: string;
    password: string;
    error_message?: string;
    success_message?: string;
    curriculum_id?: string;
    user: any; // dati dell'utente registrato su firebase
    is_logged_in: boolean;
    courses: CourseType[];
    course_index: number;
    curriculum: CurriculumType;
    lessons: LessonType[];
    new_course_added?: string; // will contain the id of the course that is being added
    new_lesson_added?: { // will contain the id of the lesson that is being edited and, if it exists, the id of the course the lesson is included in
        lesson_id: string;
        course_id?: string; // this is optional because the lession could also live on its own
    };
    lesson_updated?: string; // will contain the id of the lesson that is being edited
    curriculum_to_save_to_db?: boolean;
    student_simulation: boolean; //teacher is simulating student
};

// Serve ad evitare che vengano creati duplicati tra gli utenti.
let token_has_been_authenticated = false;

// Serve ad evitare di ottenere dati vecchi da firebase (se il curriculum e' cambiato dal promo caricamento?)
let updated_curriculum: CurriculumType = null; // WTF curriculum_to_save_to_db, updated_curriculum ???

function App() {

    // @ts-ignore
    const [state, dispatch]: [AppStateType, any] = useReducer(reducerGeneric, {
        username: 'ciro@persia.com', // TODO Demo credentials, remove after testing
        password: '12345678', // TODO Demo credentials, remove after testing
        courses: [],
        lessons: [],
        is_logged_in: true, // messo true perché altrimenti, essendo il controllo dello stato non sincrono, all'inizio verrebbe visualizzata la pagina di login, poi se però l'utente è loggato lo stato si aggiornerebbe per mostrare i corsi, perdendo però così la possibilità di linkare lezioni e corsi. Questo perchè se aprissi ad esempio il corso con il link /courses/AAAOK, lo stato, partendo con is_logged_in: false, causerebbe prima il redirect alla pagina /login, per poi accorgersi che l'utente è loggato e quindi visualizzare la pagina /courses (che è quello che avviene dopo un login avvenuto con successo).
        student_simulation: true, // teacher can become a student to test course
        curriculum_to_save_to_db: false, // just added TODO
    });
    function stateSubstitute(path: string, part: any) { return stateChangeGenericDispatch('SUBSTITUTE', path, part, dispatch); }
    function stateAdd(path: string, part: any) { return stateChangeGenericDispatch('ADD', path, part, dispatch); }
    function stateDelete(path: string) { return stateChangeGenericDispatch('DELETE', path, null, dispatch); }

    // Questo useEffect si occupa di ottenere i dati da firebase, viene lanciato ogni volta che viene ricaricata la pagina.
    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                async function retrieveStateFromDB() {
                    // Find the curriculum where hic_user.uid is equal to user.uid // user.uid is like: hotel_in_cloud_1234..
                    const curriculum_document_query = query(curricula_collection, where('hic_user.uid', "==", user.uid));
                    // Convert the query into a snapshot (a firebase object with various data)
                    const curriculum_snapshot = await getDocs(curriculum_document_query);
                    // Use the map function to create an array (because query_snapshot is NOT an array) with the documents found with the query.
                    // Because the id is unique, we will only find one curriculum, so we use [0] to retrieve it.
                    const curriculum = curriculum_snapshot.docs.map(doc => doc.data())?.[0] ?? {};

                    const courses_document = await getDocs(courses_collection);
                    const courses = courses_document.docs.map(doc => doc.data());

                    const lessons_document = await getDocs(lessons_collection);
                    const lessons = lessons_document.docs.map(doc => doc.data());

                    stateSubstitute('curriculum', updated_curriculum ?? curriculum);
                    stateSubstitute('lessons', lessons);
                    stateSubstitute('courses', courses);

                }
                retrieveStateFromDB();
                stateSubstitute('is_logged_in', true);
            } else stateSubstitute('is_logged_in', false);
        });
        return () => unsubscribe(); // unsubscribing from the listener when the component is unmounting. 
    }, []);

    // Questo if statement cerca nell'url il token. Se lo trova lo usa per esegueguire l'autenticazione e poi imposta la variabile token_has_been_authenticated = true;
    // La variabile globale token_has_been_authenticated serve per evitare duplicazioni di un utente nuovo.
    if (!token_has_been_authenticated && window.location.hash) {
        const token = window.location.hash.replace('#', '');
        signInWithCustomToken(auth, token).then(async () => {
            // @ts-ignore
            const {
                academy_roles = ['student'],
                email = '',
                first_name = '',
                last_name = '',
                username = '',
                uid,
            }: {
                academy_roles: string[];
                email: string;
                first_name: string;
                last_name: string;
                username: string;
                uid: string;
            } = jwtDecode(token);
            // console.log(jwtDecode(token), 'TOKEN DECODIFICATO');
            const curriculum_document_query = query(curricula_collection, where('hic_user.uid', "==", auth.currentUser.uid));
            const curriculum_document = await getDocs(curriculum_document_query);
            const curriculum = curriculum_document.docs.map(doc => doc.data())?.[0];

            const hic_user = {
                academy_roles,
                first_name,
                last_name,
                username,
                email,
                uid
            }
            let curriculum_reference = null;
            const new_curriculum_id = generateThreadID(5);
            if (!curriculum) {
                updated_curriculum = {
                    id: new_curriculum_id,
                    courses: [],
                    hic_user,
                }
                curriculum_reference = doc(curricula_collection, new_curriculum_id);

                await setDoc(curriculum_reference, updated_curriculum);
            } else {
                const { id } = curriculum;
                updated_curriculum = {
                    hic_user,
                    id,
                    courses: curriculum.courses,
                };
                curriculum_reference = doc(curricula_collection, id);
                await updateDoc(curriculum_reference, {
                    hic_user
                });
            }
        });
        token_has_been_authenticated = true;
    }
    return (
        <div className="App bg-gray-100 h-screen">
            <Router>
                <Switch>
                    <Route exact path="/login" render={() => {
                        return (!state.is_logged_in ? <Login state={state} stateSubstitute={stateSubstitute} />
                            : <Redirect to={{
                                pathname: '/courses',
                                //  state: window.location.hash
                            }} />)
                    }}>
                    </Route>
                    <Academy
                        state={state}
                        stateSubstitute={stateSubstitute}
                        stateAdd={stateAdd}
                        stateDelete={stateDelete}
                    />
                </Switch>
            </Router >
        </div>
    );
}

function Login({ state, stateSubstitute }: any) {

    return <div className='flex items-center flex-col'>
        <div className='space-y-4 shadow p-4 rounded-md bg-white'>
            {/*
            <div className='flex flex-col'>
                <label>{'Username'}</label>
                <input value={state.username} onChange={(event) => {
                    stateSubstitute('username', event.target.value)
                }} />
            </div>
            <div className='flex flex-col'>
                <label>{'Password'}</label>
                <input type='password' value={state.password} onChange={(event) => {
                    stateSubstitute('password', event.target.value)
                }} />
            </div>
            {state.error_message && <div>{state.error_message}</div>}
            <button
                className='active:bg-gray-200 bg-white bnb bnb-button border cursor-pointer hover:bg-gray-100 rounded text-2xl'
                onClick={async () => {
                    try {
                        const login_response = await fetch(local_config.firebase_auth_url, {
                            method: 'POST',
                            body: JSON.stringify({
                                username: state.username,
                                password: state.password
                            }),
                            headers: {
                                'Content-Type': 'application/json',
                            },
                        });
                        // if(login_response.status !== 200) throw login_response;
                        const token = await login_response.text();
                        //@ts-ignore
                        const decoded_token: {
                            academy_roles: string[];
                            aud: string;
                            email: string;
                            exp: number;
                            first_name: string;
                            iat: number;
                            iss: string;
                            last_name: string;
                            sub: string;
                            uid: string;
                            username: string;
                        } = jwtDecode(token);
                        const { academy_roles, first_name, last_name, username, email, uid } = decoded_token;
                        let curriculum_reference = null;

                        stateSubstitute('roles', decoded_token.academy_roles ?? ['student']);
                        stateSubstitute('username', decoded_token.username);
                        // const user_credentials = await signInWithCustomToken(auth, token);


                        const curriculum_document_query = query(curricula_collection, where('hic_user.uid', "==", auth.currentUser?.uid ?? ''));
                        const curriculum_document = await getDocs(curriculum_document_query);
                        const curriculum = curriculum_document.docs.map(doc => doc.data())?.[0];

                        const new_curriculum_id = generateThreadID(5);

                        const hic_user = {
                            academy_roles,
                            first_name,
                            last_name,
                            username,
                            email,
                            uid
                        };
                        
                        if (!curriculum) {
                            curriculum_reference = doc(curricula_collection, new_curriculum_id);
                            updated_curriculum = {
                                id: new_curriculum_id,
                                courses: [],
                                hic_user
                            };
                            await setDoc(curriculum_reference, updated_curriculum);
                        } else {
                            const { id } = curriculum;
                            updated_curriculum = {
                                hic_user,
                                id,
                                courses: curriculum.courses,
                            };
                            curriculum_reference = doc(curricula_collection, id);
                            await updateDoc(curriculum_reference, {
                                hic_user
                            });
                        }
                        stateSubstitute('is_logged_in', true);
                        stateSubstitute('username', '');
                        stateSubstitute('password', '');
                    } catch (error) {
                        console.log(error);
                    }
                }} style={{ display: 'block' }}>{'Login'}</button>
            */}
            <button
                className='bnb--color-accent active:bg-gray-200 bg-white bnb bnb-button border cursor-pointer hover:bg-gray-100 rounded text-2xl'
                onClick={async () => {
                    window.location.href = local_config.external_auth_url;
                }}>{/*T_R*/'Login da Hotel In Cloud'}</button>


        </div>
    </div>
}

export default App;
