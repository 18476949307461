import React from 'react';
import Swal from 'sweetalert2';
import { Link } from "react-router-dom";
import { CourseType } from '../types/types';
import { db } from './Academy';
import { doc, deleteDoc } from "firebase/firestore";
import type { AppStateType } from '../App';

export function isTeacher(state : any){
    return !! state?.curriculum?.hic_user?.academy_roles.includes('teacher');
}

export function isTeacherActingAsTeacher(state : any){ //real teacher, not impersonating student
    return isTeacher(state) && !state.student_simulation;
}

//only teacher can switch between roles
export async function teacherToggleRole({ state, stateSubstitute }: { state: AppStateType, stateSubstitute: any }){
    if(!isTeacher(state)) return; // only teacher can pretend to be students

    await Swal.fire({
        title: /*T_R*/'Sei sicuro',
        text: /*T_R*/'Rimarrai un insegnante ma passerai dalla progettazione del corso alla simulazione degli studenti',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: /*T_R*/`Si', cambia ruolo`,
        allowEnterKey: false
      }).then((result) => {
        if (result.isConfirmed) {
            let future_student_simulation = !state.student_simulation;
            stateSubstitute('student_simulation', future_student_simulation);
            Swal.fire(
                /*T_R*/'Confirmato!',
                /*T_R*/`Stai ora agendo come: ${future_student_simulation ? 'studente' : 'insegnante'}`,
                'success'
            )
        }
      });

}

export function Courses({ state, stateDelete }: { state: AppStateType, stateDelete: any }) {

    return <div className='space-y-4 flex flex-col'>
        {state.courses.map((course: CourseType, course_index: number) => {
            const course_reference = doc(db, 'courses', course.id);
            return <div className='bg-white flex items-center space-x-4 shadow rounded-md p-4 justify-between' key={`${course_index}`}>
                <div>
                    <h2 className={`text-lg leading-6 font-medium text-gray-900 ${state.curriculum?.hic_user?.academy_roles?.includes('teacher') ? 'inline' : ''}`}>{course.title}</h2>
                    {isTeacherActingAsTeacher(state) ?
                        <button
                            className='active:bg-red-200 bg-white bnb-button bnb-button--cancel border-transparent cursor-pointer hover:bg-red-100 rounded text-sm'
                            onClick={async () => {
                                await Swal.fire({
                                    title: /*T_R*/'Sei assolutamente sicuro?',
                                    text: /*T_R*/`Il corso sara' cancellato in maniera definitiva!`,
                                    icon: 'warning',
                                    showCancelButton: true,
                                    confirmButtonText: /*T_R*/'Si, cancella',
                                    allowEnterKey: false
                                  })
                                  .then((result) => {
                                    if (result.isConfirmed) {
                                        deleteDoc(course_reference).catch(error => {
                                            Swal.fire(/*T_R*/'Errore!', `${error}`, 'error');
                                            return;
                                        }).then( () => stateDelete(`courses:${course_index}`) )
                                    }
                                  })                                
                            }}>{/*T_R*/'Cancella il corso'}</button>
                        : null}
                    <span>{course.subtitle}</span>
                </div>
                <Link
                    style={{ textDecoration: 'none' }}
                    className='bg-gray-100 cursor-pointer p-4 text-gray-900 hover:bg-gray-200 rounded'
                    key={`${course_index}`}
                    to={{
                        pathname: `/course/${course.id}`,
                    }}
                >
                    {/*T_R*/'Apri'}
                </Link>

            </div>
        })}
    </div>
}
