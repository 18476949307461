import React from 'react';
import { Link } from "react-router-dom";
import { generateThreadID } from '../utils/utility_functions';
import { CourseType } from '../types/types';
import { db } from './Academy';
import type { AppStateType } from '../App';
import { doc, setDoc, arrayRemove, updateDoc } from "firebase/firestore";
import Swal from 'sweetalert2';
import {
    useHistory // Tra le sue funzionalità esiste la possibilità di fare redirect (ad esempio come viene fatto per un corso appena aggiunto)
} from "react-router-dom";

import { isTeacherActingAsTeacher } from './Courses';
import { gradeLesson , GradesFeedback} from './Lesson';


export function Course({ state, course, stateSubstitute, stateAdd, stateDelete, course_index }: { //TODO index e' pericoloso, usare solo id's!
    state: AppStateType;
    course_index: number;
    course: CourseType;
    stateSubstitute: any;
    stateAdd: any;
    stateDelete: any;
}
) {
    const course_globalpath = `courses:${course_index}`;

    const course_reference = doc(db, 'courses', course.id);
    const history = useHistory();
    let curriculum_course = state.curriculum?.courses?.find((c) => c?.id === course.id) || null;

    return <div key={course.id} className='lg:ciao-w-1_3 space-y-4'>
        { isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('teacher')*/ ? <div className='space-y-2 shadow bg-white rounded-md'> <div className='p-4'>
            <div className='flex flex-col'>
                {/*T_R*/'Titolo del corso: '} <input value={course.title ?? ''} onChange={(event) => {
                    stateSubstitute(`${course_globalpath}:title`, event.target.value);
                }} />
            </div>
            <div className='flex flex-col'>
                {/*T_R*/'Sottotitolo del corso: '} <input value={course.subtitle ?? ''} onChange={(event) => {
                    stateSubstitute(`${course_globalpath}:subtitle`, event.target.value);
                }} />
            </div>
            </div>
            {isTeacherActingAsTeacher(state) ? /*probabilmente inutile */
            <div className='px-4 py-3 bg-gray-50 text-right space-x-2'>
                {(state.new_course_added !== course.id) ? /*altrimenti bottone sotto*/
                <button
                    className='cursor-pointer bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
                    onClick={async () => {
                        try {
                            await setDoc(course_reference, course);
                            //console.log('Course updated!')
                        } catch (error) {
                            Swal.fire(/*T_R*/'Errore!', `${error}`, 'error');
                            return;
                        }
                    }}>{/*T_R*/'Salva i cambiamenti'}</button>
                : null}
            </div>
            : null}
        </div> : <div className='space-y-2'>
            <h2>{course.title}</h2>
            <span>{course.subtitle}</span>
            {curriculum_course?.grade ? <div>{/*T_R*/'Completato con un voto di: '}{curriculum_course.grade}</div> : null}
        </div>}

        {state.new_course_added !== course.id ? null :
            <button
                className='cursor-pointer bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
                onClick={async () => {
                    if (!course.title || !course.subtitle) {
                        Swal.fire(/*T_R*/'Incompleto!', /*T_R*/'Prima inserisci titolo e sottotitolo', 'warning');
                        return;
                    }
                    try {
                        await setDoc(course_reference, course);
                        Swal.fire('OK!', /*T_R*/'Corso salvato!', 'success');
                    } catch (error) {
                        Swal.fire(/*T_R*/'Errore!', `${error}`, 'error');
                        return;
                    }
                    stateSubstitute('new_course_added', '');
                }}>{/*T_R*/'Salva il corso'}</button>}

        <div className='space-y-4'>
            <h2>{/*T_R*/'Le tue lezioni'}</h2>
            {course?.lessons?.map((lesson_id_container, lesson_index) => {
                //lesson_id_container qui contiene solo {id: 'CB9P6'}, bisogna recuperare complete_lesson dallo state
                let complete_lesson = state.lessons.find(l => l.id === lesson_id_container.id);
                let curriculum_lesson = curriculum_course?.lessons.find(l => l.id === lesson_id_container.id) ?? null; // curriculum_course?.lessons[lesson_index] ?? null; //TODO: attenzione agli indici, meglio gli id .find((c) => c?.id === lesson_id_container.id)
                //console.log({curriculum_lesson,complete_lesson});
                let grades = gradeLesson(curriculum_lesson,complete_lesson); 

                return <div key={`${lesson_id_container.id}`} className='bg-white p-4 shadow rounded-md flex justify-between'>
                    <div className='flex space-x-4'>
                        {isTeacherActingAsTeacher(state) ? 
                            <button
                                className='active:bg-red-200 bg-white bnb-button bnb-button--cancel border-transparent cursor-pointer hover:bg-red-100 rounded text-sm'
                                onClick={async () => {
                                    const swal_result = await Swal.fire({
                                        icon: 'warning',
                                        text: 'Sei sicuro?',
                                        confirmButtonText: 'Sì, cancella',
                                        showCancelButton: true,
                                        cancelButtonText: 'No, annulla',
                                    });
                                    if(!swal_result.value) return;
                                    await updateDoc(course_reference, {
                                        lessons: arrayRemove({ id: lesson_id_container.id })
                                    }).catch(error => {
                                        Swal.fire(/*T_R*/'Errore!', `${error}`, 'error');
                                        return;
                                    })
                                    stateDelete(`courses:${course_index}:lessons:${lesson_index}`);
                                }}>{/*T_R*/'Cancella la lezione'}
                            </button>
                        : null}
                        <div>
                            <h2>{complete_lesson?.title}</h2>
                            <div>{complete_lesson?.subtitle}</div>
                            <GradesFeedback grades={grades} curriculum_lesson={curriculum_lesson} />
                        </div>
                    </div>
                    <Link
                        style={{ textDecoration: 'none' }}
                        className='bg-gray-100 cursor-pointer p-4 text-gray-900 hover:bg-gray-200 rounded'
                        key={`/lesson/${lesson_id_container.id}`} to={{
                            pathname: `/lesson/${lesson_id_container.id}`,
                            state: {
                                course_id: course.id,
                            }
                        }}>{/*T_R*/'Apri'}</Link>
                </div>
            })}
        </div>
        { isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ && state.new_course_added !== course.id ? //TODO ??? || ==> &&
            <button
                className='inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer'
                onClick={() => {
                    const new_lesson_id = generateThreadID(5);
                    stateSubstitute('new_lesson_added', { lesson_id: new_lesson_id, course_id: course.id });
                    stateAdd('lessons', {
                        id: new_lesson_id,
                        pdf: '',
                        subtitle: '',
                        tests: [],
                        title: '',
                        video: { service: '', id: '' }
                    });
                    stateAdd(`${course_globalpath}:lessons`, { id: new_lesson_id });
                    history.push(`/lesson/${new_lesson_id}`);
                }}>{/*T_R*/'Aggiungi una lezione'}
            </button>
        : null}
    </div>;
}
