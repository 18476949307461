/**
 * returns today in ISO  todayISO() --> '2021-03-12'
 * @function todayISO
 * @return {string}
 */
export function todayISO() {
    // return new Date().toISOString().slice(0,10);

    let tzoffset = new Date().getTimezoneOffset() * 60000; //offset in milliseconds
    let localISOTime = new Date(Date.now() - tzoffset).toISOString();

    return localISOTime.slice(0, 10);
}

/**
 * transform a general ISO date string like '2020-08-29' or 1996-10-15T00:05:32.000Z into the Italian string '29/08/2020'
 * @function fromISODateToItalian
 * @param {string} iso_Date
 * @return {string}
 */
export function fromISODateToItalian(iso_Date) {
    if (iso_Date.length > 10)
        //assume ISO date like dateobj.toISOString() --> 1996-10-15T00:05:32.000Z
        iso_Date = iso_Date.split('T')[0];

    let splitted_date = iso_Date.split('-');
    if (splitted_date.length !== 3) return ''; //for some reason the number of pieces could be less than 3

    splitted_date = [splitted_date[2], splitted_date[1], splitted_date[0]];
    return splitted_date.join('/');
}
