export function deepCopyFunction(inObject){
    let outObject, value, key;
    if (typeof inObject !== 'object' || inObject === null) {
        return inObject; // Return the value if inObject is not an object
    }
    if(inObject instanceof Date){
        return new Date(inObject)
    }
    // Create an array or object to hold the values
    outObject = Array.isArray(inObject) ? [] : {};
    for (key in inObject) {
        value = inObject[key];
        // Recursively (deep) copy for nested objects, including arrays
        outObject[key] = deepCopyFunction(value);
    }
    return outObject;
}

export const toggleElementInArray = (array, item) => {
    const output = array;
    if (array.includes(item)) {
        return array.filter((element) => {
            return element !== item;
        });
    }
    output.push(item);
    return output;
};

const LETTERS_AND_NUMBERS = "ABCDEFGHJKLMNPRSTUVWXYZ23456789";
export function generateThreadID(length) {
    let thread_id = '';
    for (let i = 0; i < length; i++)
        thread_id += LETTERS_AND_NUMBERS[Math.floor(Math.random() * LETTERS_AND_NUMBERS.length)]; // id of thread created randomly TODO: check uniqueness
    return thread_id;
} 