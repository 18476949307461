import React from 'react';

import { useLocation } from "react-router-dom";

import Swal from 'sweetalert2';

import {
    doc,
    setDoc,
    updateDoc,
    arrayUnion,
    arrayRemove,
    DocumentReference,
} from "firebase/firestore";

import { deepCopyFunction, generateThreadID } from '../utils/utility_functions';
import { fromISODateToItalian, todayISO } from '../utils/dates';
import { ClaimType, TestType, LessonType } from '../types/types';
import { db } from './Academy';
import type { AppStateType } from '../App';
import { isTeacherActingAsTeacher } from './Courses';

// "lesson": {
    //     "id": "9MJET",
    //     "tests": [
    //       {
    //         "id": "AUMC7",
    //         "claims": [
    //           {
    //             "id": "RMV27",
    //             "correct": true //true answer
    //           },
    // ...
    //   "curriculum_lesson": {
    //     "id": "9MJET",
    //     "tests": [
    //       {
    //         "id": "AUMC7",
    //         "claims": [
    //           {
    //             "RMV27": true  //given answer
    //           },
    // ...


// for each lesson we register in curriculum if/how tests have been answered by 'copying' the lesson structure (with some modifications)
// gradeLesson returns { "tot_correct": 2, "tot_wrong": 0 }
//          null if tests not yet taken
export function gradeLesson(curriculum_lesson:any,lesson:any){

        if(!curriculum_lesson || !lesson || ! curriculum_lesson?.completion_date) return null;

        let result:any = {'tot_correct':0, 'tot_wrong':0};
        let correct_answers:any = {};
        for(let test of lesson.tests){
            for(let claim of test.claims){
                correct_answers[`${test.id}-${claim.id}`] = claim.correct; // true or  false is the correct answer
            }
        }

        for(let test of curriculum_lesson.tests){
            for(let claim of test.claims){  //{"RMV27": true}
                if(!test.confirmed) return null; // TODO: test not yet taken (results cannot be used, let's always check completion_date before using data)
                let claim_id = Object.keys(claim)?.[0] ?? null;
                if(claim_id) {
                    if(correct_answers[`${test.id}-${claim_id}`] === claim[claim_id]) result.tot_correct++; // correct or wrong answer ?
                    else result.tot_wrong++; 
                } else {
                    return null; // TODO: test not yet taken (results cannot be used, let's always check completion_date before using data)
                }
            }
        }

        return result;
    }

export function GradesFeedback({grades,curriculum_lesson}: {grades:any, curriculum_lesson:any}){
    if(!grades || !curriculum_lesson || !curriculum_lesson.completion_date) return <span></span>;

    return <span> 
        {`Risposte corrette: `} <b>{`${grades.tot_correct}/${grades.tot_correct + grades.tot_wrong}`} {grades.tot_wrong === 0 ? ` ...ottimo!` : ''}</b> 
        {` Fatto il `} {fromISODateToItalian(curriculum_lesson.completion_date)}
        </span>;
}

export function Lesson({ lesson, stateSubstitute, state, stateAdd, lesson_index, stateDelete }: {
    stateDelete: (path: string) => void;
    lesson: LessonType;
    stateSubstitute: any;
    state: AppStateType;
    stateAdd: (path: string, value: any) => void;
    lesson_index: number;
}) {    
    let lesson_video_url:any = null;


    if(! lesson.video ) { //NOTE: at this moment 'youtube' is hardwired (but let's prepare for future services, and when added eliminate this part)
        state.lessons[lesson_index].video  = {service: 'youtube'}; 
        state.lesson_updated = lesson.id;
    }
    if(! lesson.video?.service) lesson.video.service = 'youtube';
    // END: NOTE



    if (lesson.video?.service === 'youtube' && lesson.video?.id)
        lesson_video_url = `https://www.${lesson?.video?.service}.com/${lesson?.video?.service === 'youtube' ? `embed/${lesson.video.id}` : ''}`;

    // useLocation is used in conjunction with the Link component to pass down props from the Course component. In this case we only need the course_id and we only need it when updating the curriculum object
    const { state: course_state }: { state: { course_id: string; }; } = useLocation(); //WTF
    let course_id = '';
    if (course_state)
        course_id = course_state.course_id;
    const curriculum_course_index = state.curriculum?.courses?.findIndex((c: { id: string; }) => c.id === course_id);
    const curriculum_lesson_index = state.curriculum?.courses?.[curriculum_course_index]?.lessons?.findIndex((l: { id: string; }) => l.id === lesson.id);

    let curriculum_lesson = state.curriculum?.courses?.[curriculum_course_index]?.lessons[curriculum_lesson_index] ?? null; 
    let lesson_globalpath = `lessons:${lesson_index}`; //TODO: perche' insistiamo con gli indici?, poi queste continue sostituzioni non servono a nulla

    let grades = gradeLesson(curriculum_lesson,lesson); 
    
    const lesson_reference = doc(db, 'lessons', lesson.id);
    // course_reference is used to reference the course document where the lesson id will be inserted. If this reference does not exist (when adding a new lesson separate from all courses) then only the lesson will be saved.
    let course_reference: DocumentReference = null;
    if (state.new_lesson_added?.course_id) course_reference = doc(db, 'courses', state.new_lesson_added.course_id);
    return <div className='lg:ciao-w-1_2 flex flex-col space-y-4' style={{ margin: '0 auto' }} key={lesson.id}>
        <div className='bg-white p-4 shadow rounded-md space-y-4'>
            {isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('teacher')*/ ? <>
                <div className='flex flex-col'>
                    {/*T_R*/'Titolo della lezione'}: <input value={lesson.title ?? ''} onChange={(event) => {
                        stateSubstitute(`${lesson_globalpath}:title`, event.target.value);
                        // do not set lesson_updated when just adding a lesson
                        if (!state.new_lesson_added?.lesson_id) stateSubstitute('lesson_updated', lesson.id);
                    }} />
                </div>
                <div className='flex flex-col'>
                    {/*T_R*/'Sottotitolo della lezione'}: <input value={lesson.subtitle ?? ''} onChange={(event) => {
                        stateSubstitute(`${lesson_globalpath}:subtitle`, event.target.value);
                        // do not set lesson_updated when just adding a lesson
                        if (!state.new_lesson_added?.lesson_id) stateSubstitute('lesson_updated', lesson.id);
                    }} />
                </div>
                <div className='flex flex-col'>
                    {/*T_R*/'Servizio video'}: 
                    <select 
                        value={lesson.video?.service ?? ''}
                        onChange={(event) => {
                            return Swal.fire(/*T_R*/'Solo youtube!', /*T_R*/`Al momento solo youtube e' disponibile`, 'warning');
                            if (!('video' in lesson))
                                stateAdd(lesson_globalpath, { key: 'video', value: {} });
                            stateSubstitute(`${lesson_globalpath}:video:service`, event.target.value);
                            stateSubstitute('lesson_updated', lesson.id);}}
                    >
                        <option value="">{/*T_R*/'Seleziona il servizio video'}</option>
                        <option value="youtube">{'youtube'}</option>
                    </select>
                </div>
                <div className='flex flex-col'>
                    {/*T_R*/`Video ID, l'identificatore alfanumerico del video, es per video youtube https://www.youtube.com/watch?v=Il2s59I1fnI, inserisci solo:`} <b>Il2s59I1fnI</b>  {/*T_R*/` (vedrai il video qui sotto subito dopo l'inserimento di un ID corretto)`}<input value={lesson.video?.id ?? ''} onChange={(event) => {
                        if (!('video' in lesson))
                            stateAdd(lesson_globalpath, { key: 'video', value: {} });
                        stateSubstitute(`${lesson_globalpath}:video:id`, event.target.value);
                        stateSubstitute('lesson_updated', lesson.id);
                    }} />
                </div>
            </> : <>
                <h1>{lesson.title}</h1>
                <h4>{lesson.subtitle}</h4>
            </>}
            {!lesson_video_url ? null : <div className='flex items-center'> <iframe className='w-full' style={{ height: '450px', margin: '0 auto' }} title={lesson.title} src={lesson_video_url} referrerPolicy={'same-origin'} /> </div>}
        </div>
        {!isTeacherActingAsTeacher(state) ?
        <div>
            <GradesFeedback grades={grades} curriculum_lesson={curriculum_lesson}/>
        </div> 
        : null}
        {state.new_lesson_added?.lesson_id !== lesson.id ? null :
            <button
                className='cursor-pointer bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
                onClick={async () => {
                    if (!lesson.title) {
                        Swal.fire(/*T_R*/'Incompleto!', 'Inserisci un titolo per la lezione', 'warning');
                        return;
                    }
                    try {
                        await setDoc(lesson_reference, lesson);
                        //console.log('Lesson saved!');
                        // Se la lezione è collegata a un corso allora bisogna aggiungere un riferimento della lezione nel corso
                        if (course_reference) await updateDoc(course_reference, {
                            lessons: arrayUnion({
                                id: lesson.id
                            })
                        });
                    } catch (error) {
                        Swal.fire(/*T_R*/'Errore!', `${error}`, 'error');
                        return;
                    }
                    stateSubstitute('new_lesson_added', null);
                }}>{/*T_R*/'Salva la lezione'}
            </button>
        }
        {lesson.tests.map((test: TestType, test_index: number) => {
            const curriculum_test_index = curriculum_lesson?.tests?.findIndex((t: { id: string; }) => t.id === test.id);
            let curriculum_test = curriculum_lesson?.tests[curriculum_test_index] || null;
            const test_globalpath = `${lesson_globalpath}:tests:${test_index}`;
            return <div className='bg-white shadow rounded-md p-4 space-y-2' key={test.id}>
                {isTeacherActingAsTeacher(state) ? 
                    <button
                        className='p-0! m-0 active:bg-red-200 bg-white bnb-button bnb-button--cancel border-transparent cursor-pointer hover:bg-red-100 rounded text-sm'
                        onClick={async () => {
                            try {
                                const swal_result = await Swal.fire({
                                    icon: 'warning',
                                    text: /*T_R*/'Sei sicuro?',
                                    confirmButtonText: /*T_R*/'Sì, cancella',
                                    showCancelButton: true,
                                    cancelButtonText: /*T_R*/'No, annulla',
                                });
                                if(!swal_result.value) return;
                                await updateDoc(lesson_reference, {
                                    tests: arrayRemove(test)
                                });
                                stateDelete(test_globalpath);
                                // console.log(`Test ${test_index + 1} deleted`);
                            } catch (error) {
                                Swal.fire(/*T_R*/'Errore!', `${error}`, 'error');;
                                return;
                            }
                        }}>{/*T_R*/'Cancella il test'}</button>
                : null}
                <div className='space-y-4'>
                    { isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('teacher')*/ ? 
                        <div className='flex flex-col space-y-2'>
                        <span>{/*T_R*/'Introduzione al test: '}</span><textarea value={test.introduction} onChange={(event) => {
                            stateSubstitute(`${test_globalpath}:introduction`, event.target.value);
                            stateSubstitute('lesson_updated', lesson.id);
                        }} /> <span>{/*T_R*/'(ad es. breve riassunto sul contesto del test)'}</span>
                        </div> 
                    : <><h2>{`Test ${test_index + 1}`}:</h2> <span>{test.introduction}</span></>}

                    {test.claims.map((claim: ClaimType, claim_index: number) => {
                        const claim_globalpath = `${test_globalpath}:claims:${claim_index}${!isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ ? `:${claim.id}` : ''}`;

                        const curriculum_claim = curriculum_test?.claims?.find((c: {}) => Object.keys(c)[0] === claim.id) || null;
                        const curriculum_claim_answer = curriculum_claim !== null ? Object.values(curriculum_claim)[0] : null;
                        const claim_answer = !isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ ? curriculum_claim_answer : claim.correct;

                        // let answer_was_selected = false;
                        // if (curriculum_claim_answer !== null) answer_was_selected = true;
                        let answer_was_selected = (curriculum_claim_answer !== null);

                        const claim_true_option_selected = (isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('teacher')*/ && claim_answer !== undefined && !!claim_answer) || (answer_was_selected && claim_answer);
                        const claim_false_option_selected = (isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('teacher')*/ && claim_answer !== undefined && !claim_answer) || (answer_was_selected && !claim_answer);
                        const student_has_confirmed_test = !isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ && curriculum_test?.confirmed;

                        let true_color = '';
                        let false_color = '';
                        if (!isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ && curriculum_test?.confirmed) {
                            if (claim_true_option_selected) {
                                if (claim.correct) true_color = 'green';
                                else true_color = 'red';
                            }
                            if (claim_false_option_selected) {
                                if (!claim.correct) false_color = 'green';
                                else false_color = 'red';
                            }
                        }

                        return <div className='ciao-divider-top space-y-2' key={`${state.curriculum?.hic_user?.academy_roles}-${claim.id}-${claim_index}`}>
                            {isTeacherActingAsTeacher(state) ? 
                                <button
                                    className='p-0! m-0 active:bg-red-200 bg-white bnb-button bnb-button--cancel border-transparent cursor-pointer hover:bg-red-100 rounded text-sm'
                                    onClick={async () => {
                                        try {
                                            const swal_result = await Swal.fire({
                                                icon: 'warning',
                                                text: /*T_R*/'Sei sicuro?',
                                                confirmButtonText: /*T_R*/'Sì, cancella',
                                                showCancelButton: true,
                                                cancelButtonText: /*T_R*/'No, annulla',
                                            });
                                            if(!swal_result.value) return;
                                            // TODO Update this deletion method so that it uses arrayRemove instead of deep copying
                                            const copy_of_lesson = deepCopyFunction(lesson);
                                            copy_of_lesson.tests[test_index].claims = copy_of_lesson.tests[test_index].claims.filter((c: { id: string }) => c.id !== claim.id);
                                            await updateDoc(lesson_reference, copy_of_lesson);
                                            stateDelete(claim_globalpath);
                                        } catch (error) {
                                            return Swal.fire({
                                                icon: 'error',
                                                text: JSON.stringify(error),
                                            })
                                        }
                                    }}
                                > {/*T_R*/'Cancella affermazione'}
                                </button>
                            : null}
                            <div className='space-y-2'>
                                { isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('teacher')*/
                                    ? <div className='flex flex-col space-y-2'>
                                        <span>{/*T_R*/'Affermazione'}:</span>
                                        <textarea
                                            value={claim.claim ?? ''}
                                            onChange={(event) => {
                                                stateSubstitute(`${claim_globalpath}:claim`, event.target.value);
                                                stateSubstitute('lesson_updated', lesson.id);
                                            }}
                                        ></textarea>
                                    </div>
                                    : <span>{claim.claim} 
                                        {student_has_confirmed_test ? <b>{claim.correct ? '...vero!' : '...falso!'}</b> : null}
                                      </span>
                                }
                                <div>
                                    <input type='radio' id={`claim-${claim.id}-true`} disabled={student_has_confirmed_test} checked={!!claim_true_option_selected} onChange={() => {
                                        if (!isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('student')*/) setClaim(test, claim, true);
                                        else {
                                            if (!('correct' in claim)) { stateAdd(claim_globalpath, { key: 'correct', value: true }); }
                                            else stateSubstitute(`${claim_globalpath}:correct`, true);
                                            stateSubstitute('lesson_updated', lesson.id);
                                        };
                                    }} />
                                    <label style={{ color: true_color }} htmlFor={`claim-${claim.id}-true`}>{/*T_R*/'Vero'}</label>
                                    <input type="radio" id={`claim-${claim.id}-false`} disabled={!isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ && curriculum_test?.confirmed} checked={!!claim_false_option_selected}
                                        onChange={() => {
                                            if (!isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('student')*/)
                                                setClaim(test, claim, false);
                                            else {
                                                if (!('correct' in claim)) { stateAdd(claim_globalpath, { key: 'correct', value: false }); }
                                                else stateSubstitute(`${claim_globalpath}:correct`, false);
                                                stateSubstitute('lesson_updated', lesson.id);
                                            };
                                        }} />
                                    <label style={{ color: false_color }} htmlFor={`claim-${claim.id}-false`}>{/*T_R*/'Falso'}</label>
                                </div>
                            </div>
                        </div>
                    })}
                    {curriculum_test && !isTeacherActingAsTeacher(state) /*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ && !curriculum_test.confirmed ? <div>
                        <button
                            className='cursor-pointer bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
                            onClick={() => {
                                if (test.claims.length > curriculum_test.claims.length) {
                                    Swal.fire('Incomplete!', 'You must answer all claims to submit the test', 'warning');
                                    return;
                                }
                                let correct_answers = 0;
                                for (let claim of curriculum_test.claims) {
                                    let claim_id = Object.keys(claim)[0];
                                    let answer = claim[claim_id];
                                    let index_of_test_claim = test.claims.findIndex((c: { id: string; }) => c.id === claim_id);
                                    if (test.claims[index_of_test_claim].correct === answer)
                                        correct_answers++;
                                }
                                stateAdd(`curriculum:courses:${curriculum_course_index}:lessons:${curriculum_lesson_index}:tests:${curriculum_test_index}`, { key: 'confirmed', value: true });
                                // Add completion_date for lesson but only if every test has been confirmed (minus the current test because the state wouldn't be updated yet)
                                const other_lesson_tests = curriculum_lesson?.tests?.filter(t => t.id !== curriculum_test.id);
                                if (other_lesson_tests && other_lesson_tests.every((test) => test.confirmed)) {
                                    stateAdd(`curriculum:courses:${curriculum_course_index}:lessons:${curriculum_lesson_index}`, { key: 'completion_date', value: todayISO() });
                                }
                                stateSubstitute('curriculum_to_save_to_db', true);
                                // console.log(test.claims.length, { correct_answers });
                            }}>{/*T_R*/'Confirma le risposte'}</button>
                    </div> : null}
                    {isTeacherActingAsTeacher(state) ? 
                        <button
                            className='inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer'
                            onClick={() => {
                                stateAdd(`${test_globalpath}:claims`, {
                                    claim: '',
                                    id: generateThreadID(5),
                                });
                                stateSubstitute('lesson_updated', lesson.id);
                            }}>{/*T_R*/`Aggiungi un'affermazione`} {/*T_R*/`(domanda con risposta vero o falso`}
                        </button>
                    : null}
                </div>
            </div>;
        })}
        {! isTeacherActingAsTeacher(state) || state.new_lesson_added?.lesson_id ? null :
            <div>
                <button
                    className='inline-flex items-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 cursor-pointer'
                    onClick={() => {
                        const new_test_id = generateThreadID(5);
                        stateAdd(`${lesson_globalpath}:tests`, {
                            claims: [],
                            id: new_test_id,
                            introduction: ''
                        });
                        stateSubstitute('lesson_updated', lesson.id);
                    }}>{/*T_R*/'Aggiungi un test'}</button></div>}
        {state.new_lesson_added?.lesson_id || !state.lesson_updated || !state.curriculum?.hic_user?.academy_roles?.length || !isTeacherActingAsTeacher(state)/*state.curriculum?.hic_user?.academy_roles?.includes('student')*/ ? null :
            <button
                className='cursor-pointer bg-gray-800 border border-transparent rounded-md shadow-sm py-2 px-4 inline-flex justify-center text-sm font-medium text-white hover:bg-gray-900 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900'
                onClick={async () => {
                    // Lesson validation
                    if (!lesson.title) {
                        Swal.fire('Incomplete!', /*T_R*/'Inserisci un titolo per questa lezione', 'warning');
                        return;
                    }
                    for (let test of lesson.tests) {
                        for (let claim of test.claims) {
                            if (!claim.claim) {
                                Swal.fire('Incomplete!', /*T_R*/'Alcune affermazioni non hanno un testo', 'warning');
                                return;
                            }
                            if (!('correct' in claim)) {
                                Swal.fire('Incomplete!', /*T_R*/'Alcune affermazioni non hanno le risposte corrette', 'warning');
                                return;
                            }
                        }
                    }
                    try {
                        await setDoc(lesson_reference, lesson);
                        // console.log('Lesson updated');
                    } catch (error) {
                        Swal.fire(/*T_R*/'Errore!', `${error}`, 'error');;
                        return;
                    }
                    stateSubstitute('lesson_updated', '');
                }}>{/*T_R*/'Aggiorna la lezione'}
            </button>}
    </div>


    function setClaim(test: TestType, claim: ClaimType, answer: boolean) { //WTF
        const copy_of_curriculum = deepCopyFunction(state.curriculum);
        if (!('courses' in copy_of_curriculum))
            copy_of_curriculum.courses = [];
        if (!copy_of_curriculum.courses.some((c: { id: string; }) => c.id === course_id)) {
            copy_of_curriculum.courses.push({
                id: course_id,
                lessons: [],
            });
        }
        const course_index = copy_of_curriculum.courses.findIndex((c: { id: string; }) => c.id === course_id);
        if (!('lessons' in copy_of_curriculum.courses[course_index]))
            copy_of_curriculum.courses[course_index].lessons = [];
        if (!copy_of_curriculum.courses[course_index].lessons?.some((l: { id: string; }) => l.id === lesson.id)) {
            copy_of_curriculum.courses[course_index].lessons.push({
                id: lesson.id,
                tests: []
            });
        }
        const lesson_index = copy_of_curriculum.courses[course_index].lessons.findIndex((l: { id: string; }) => l.id === lesson.id);
        if (!copy_of_curriculum.courses[course_index].lessons[lesson_index].tests?.some((t: { id: string; }) => t.id === test.id)) {
            copy_of_curriculum.courses[course_index].lessons[lesson_index].tests.push({
                id: test.id,
                claims: []
            });
        }
        const test_index = copy_of_curriculum.courses[course_index].lessons[lesson_index].tests.findIndex((t: { id: string; }) => t.id === test.id);
        if (!copy_of_curriculum.courses[course_index].lessons[lesson_index].tests[test_index].claims?.some((c: { [id: string]: boolean; }) => Object.keys(c).includes(claim.id))) {
            copy_of_curriculum.courses[course_index].lessons[lesson_index].tests[test_index].claims.push({
                [claim.id]: answer
            });
        } else {
            const claim_index = copy_of_curriculum.courses[course_index].lessons[lesson_index].tests[test_index].claims.findIndex((c: { [id: string]: boolean; }) => Object.keys(c).includes(claim.id));
            copy_of_curriculum.courses[course_index].lessons[lesson_index].tests[test_index].claims[claim_index][claim.id] = answer;
        }
        stateSubstitute('curriculum', copy_of_curriculum);
    }
}
